<template>
  <div class="tc-container">
    <h1 class="">Terms and conditions</h1>
    <p class="">Last updated: July 2, 2023</p>
    <p>
      Welcome to EcoGameCenter.net (the “Website”). The following Terms and Conditions
      govern your use of our Website and the services we offer, including playing our
      online games and participating in our tournaments (collectively, the "Services").
      Please read these Terms and Conditions carefully before accessing or using our
      Services. By accessing or using any part of the Services, you agree to be bound by
      these Terms and Conditions. If you do not agree to all the terms and conditions of
      this agreement, then you may not access the Website or use any Services.
    </p>
    <ol class="">
      <li v-for="(condition, i) in conditions" :key="i">
        <h4>{{ condition.title }}</h4>
        <p class="grey-text">{{ condition.message }}</p>
      </li>
    </ol>
    <p>
      Please review our Privacy Policy, which also governs your use of the Services, to
      understand our practices.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conditions: [
        {
          title: "Account Eligibility",
          message:
            "To register an account and use our Services, you must be at least 18 years of age or older, or the age of legal majority in your jurisdiction of residence, whichever is older. By creating an account and using our Services, you represent that you meet this age requirement.",
        },
        {
          title: "Account Responsibility",
          message:
            "You are responsible for maintaining the confidentiality of your account information, including your username and password. You are responsible for all activities that occur under your account and agree to notify us immediately of any unauthorized access or use of your account.",
        },
        {
          title: "Tournament Participation",
          message:
            "Participation in tournaments requires an entry fee, payable in accordance with our pricing terms. Winners of tournaments will be awarded prizes in the form of cash or other benefits, as specified for each tournament.",
        },
        {
          title: "Code of Conduct",
          message:
            "You agree to behave in a respectful, ethical manner while using our Services and not to engage in any activity that interferes with or disrupts the Services.",
        },
        {
          title: "Intellectual Property",
          message:
            "All content on this Website, including but not limited to text, images, graphics, or code is copyrighted as a collective work under copyright laws, and is the property of EcoGameCenter.net.",
        },
        {
          title: "Limitation of Liability",
          message:
            "EcoGameCenter.net shall not be liable for any direct, indirect, incidental, special or consequential damages resulting from the use or inability to use the Services, or from any goods, data, information, or services purchased or obtained, or messages received or transactions entered into through the Services.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tc-container {
  padding: 100px 10%;
  color: white;
  @media only screen and (max-width: 450px) {
    h1 {
      font-size: 20px;
    }
  }
}
p,
li {
  font-size: 14px;
  margin-top: 15px !important;
}
.grey-text {
  color: rgb(179, 179, 179);
}
</style>
